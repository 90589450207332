// Responsive tabs
.breakpoint {
    &__sm {
        .react-tabs {
            // display: none;
        }
    }
}


.tabs {
    &__link {
        display: inline-block;
        border: 1px solid transparent;
        border-bottom: none;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
    }
}

.react-tabs {
    &__tab-list {
        border-bottom: 0px;;
    }
    &__tab {
        &--selected {
            color: var(--brand-blue);
            font-weight: 600;
            border-bottom: 3px solid var(--brand-blue);
            border-left: 0px;
            border-right: 0px;
            border-top: 0px;
            background: none;
        }
    }
    
    .vertical-tabs {
        .react-tabs {
            &__tab-list {
                
            }
            
            &__tab {
                border: 0px;
                border-radius: 0px;
                display: block;
                &--selected {
                        border-left: 3px solid var(--brand-blue) !important;
                    
                }
            }
        }
    }
}