@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
// @import 'node_modules/react-modal-video/scss/modal-video.scss';

.ThankYou {
    &__img {
        svg {
            max-width: 250px !important;
            margin: 0 auto;
            display: block;
            text-align: center;
        }
    }
}