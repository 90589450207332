.btn {
    &--primary,
    &-primary {
        color: #fff !important;
        background-color: var(--brand-blue) !important;
        border-width: 0px !important;
        border-color: none !important;
        border-style: none !important;
        &:hover,
        &:focus {
            background: var(--brand-blue-light) !important;
        }
        
        
    }
    &--ghost {
        border-radius: 3px;
        border-color: var(--brand-blue) !important;
        color: var(--brand-blue) !important;
        border-width:2px !important;
        background: transparent !important;
        border-style: solid !important;
        &:hover {
            color: #fff !important;
        }
    }
}