.networking {
    img {
        max-width: 100%;
    }
    
            &__video{
            
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
                object-position: 80% 0%;
                
            }
}