.benefits {
    position: relative;
    overflow: hidden;
    &__content {
        position: relative;
        z-index: 1;
    }
    &__background {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
    }
}