
.page-hero {
    &__logo {
        margin-bottom: 0px !important;
        img {
            width: 50% !important;
            max-width: 300px;
        }
    }
}
