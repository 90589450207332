// @import "../vars/spacing";

section,
.section {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &.end {
        padding-bottom: 0;
        
    }
    &__content {
        position: relative;
        z-index: 1;
    }
    &__background {
            z-index: 0;
            overflow: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        img {
            object-fit: cover;
            width: 100%;
        }
    }
    &.grey-background,
    &.gray-background {
        background: var(--light-grey);
    }
}