@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.LifecycleServices {
    .indemand-logo {
        max-width: 300px;
        max-height: 66px;
        width: 300px;
        height: 66px;
    }
    .deployments {
        img {
            width: 100%;
        }
    }
    
     .partners {
        .swiper-container-horizontal{
            overflow: hidden;
        }
     }
}