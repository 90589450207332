@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

$contentBackground: var(--light-grey);
    
.CoreHardware {
    
    
    
    .hardware {
        &__tabs {
            background: $contentBackground
        }
    }
    .swiper-slide {
        &__background {
        height: 100%;
            
        }
    }
    .core-tech {
        .hardware-card {
            @include media-breakpoint-only(md) {
                min-height:435px;    
           }
            @include media-breakpoint-up(xl) {
                min-height:450px;    
           }
        }
    }
}
    
    
.hardware {
    &__content {
        // background: $contentBackground;
    }
}