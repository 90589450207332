@mixin h1 {

    @include font-size(3.3888889rem);
    font-weight: 300;
    line-height: 4rem;
    margin-top: 1.3333333rem;
    margin-bottom: 5.3333332rem;
}

@mixin h2 {
    
    @include font-size (2.2777778rem);
    line-height: 2.6666667rem;
    margin-top: 1.3333333rem;
    margin-bottom: 2.6666666rem;

}

@mixin h3 {
    
    @include font-size(1.5rem);
     font-size: 1.5rem;
    line-height: 2.6666667rem;
    margin-top: 1.3333333rem;
    margin-bottom: 0rem;
}

@mixin h4 {
    
    @include font-size( 1rem);
    line-height: 1.3333333rem;
    margin-top: 1.3333333rem;
    margin-bottom: 0rem;
}

@mixin h5 {
    
    @include font-size( 1rem);
    line-height: 1.33333333em;
    margin-top: 1.33333333em;
    margin-bottom: 0em;
}