.contact-form {
    
}
#validation {
    .success {
        margin-bottom: var(--spacer-3);
        border: 3px solid var(--brand-green);
        padding: var(--spacer-3);
        text-align: center;
        color: var(--brand-green);
    }
}

.form-group {
    input[type="email"], 
    input[type="tel"], 
    input[type="text"], 
    textarea {
        background: #f0f4ed;
        border-radius: 2px;
        border: 1px solid #d9ead1;
        outline: 0;
        font-size: 1em !important;
        &:focus,
        &:active{
            border: 1px solid var(--brand-green);   
            box-shadow: none;
        }
    }
    
    textarea {
        height: 10em;
    }
    
    .react-tel-input {
        .form-control {
            height: auto;
            width: 100%;
        }
    }
}

    
input[type="submit"]{
    margin-top: var(--spacer-2) !important;    
}
