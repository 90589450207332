.video {
    &--full-width {
        
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
            max-height: 50vh ;
           
    }
    &--sidebar {
        
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
            // max-height: 50vh ;
           
    }
}