

.row {
    margin-bottom: 1.5em;
}

@media (min-width: 1400px) {
    
    // Set maximum container size
    .container, 
    .container-lg, 
    .container-md, 
    .container-sm, 
    .container-xl, 
    .container-xxl {
        max-width: 1620px;
    }
}