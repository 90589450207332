@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "~rfs/scss";

.page-hero {
        // padding-top: 0;
        
            @include media-breakpoint-down(sm) {
                // text-align: center;    
                padding-top: 0;
            }
        overflow: hidden;
        
        &__content-wrap {
            position: relative;
            z-index: 1;
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
        
        &__content {
            
            position: relative;
            z-index: 1;
            @include media-breakpoint-down(md) {
                // text-align: center;    
            }
        }
        
        &__background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 0;
        }
        &__background-color {
            background-color:#192c36;
            opacity: .8;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &__video {
            overflow: hidden;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

// .page-hero{
//     padding-top: 0px !important;
//     padding-bottom: 0px !important;
//     position: relative;
//     overflow: hidden;
//     &__img {
//         position: absolute;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }
//     &__content {
            
//         z-index: 1;
//         position: relative;
//         padding-top:    var(--spacer-10);
//         padding-bottom: var(--spacer-10);
//     }
//     &__background {
        
//         overflow: hidden;
//         position: absolute;
//         top: 0;
//         z-index: 0;
//     }
// }