@import "../css/vars/colors";
@import "../css/vars/spacing";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

.navigation { 

    background: #ffffff;
    &__button {
        justify-content:right;
    }
    .navbar-brand {
        align-self: flex-start;
        img {
            max-width: 160px;
            max-height: 35px;
            margin-bottom: 0;
            height: 35px;
        }
    }
    
    &__mobile-toggle {
        button {
            // float:right;
        }
    }
    
    &__menu {
        // margin-left: auto;
        a {
            color: var(--brand-green);
            text-decoration: none;
        }
        .dropdown-menu {
            a {
                &:active {
                    background-color: var(--brand-green);
                    // color: #fff;
                }
            }
        }
            
            @include media-breakpoint-down(lg) {
                float: left;
                
            }
    }
    
    .dropdown-item {
        white-space: pre-wrap;
        @include media-breakpoint-down(lg) {    
            padding-left: 0;
        }
    }
    .dropdown-menu {
        // Dropdowns
        &__content {
            z-index: 1;
            position:relative;
        }
        &__background {
            background: var(--light-grey);
            display: none;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            @include media-breakpoint-up(lg) {
                width: 35%;
                display: block;
            }
        }
    }
    .productsNav,
    .servicesNav,
    .hardwareNav,
    .aboutNav {
        .dropdown-menu {
            // margin-top: 1em;
            &__content {
                @include media-breakpoint-only(md) {
                    min-width: 20em;
                }
                @include media-breakpoint-up(lg) {
                    min-width: 35em;
                }
            }
            
        }
    }
    
    
    .productsNav {
        .dropdown-menu {
            @include media-breakpoint-up(lg) {
                left: -10em;
                
            }
        }
    }
    
    .hardwareNav {
        .dropdown-menu {
            @include media-breakpoint-up(lg) {
                left: -4.5em;
                
            }
        }
    }
    .servicesNav {
        .dropdown-menu {
            
            @include media-breakpoint-only(md) {
                left: -1em;
                
            }
            @include media-breakpoint-up(lg) {
                left: -16em;
                
            }
        }
    }
    .aboutNav {
        .dropdown-menu {
            
            @include media-breakpoint-only(md) {
                left: -6.5em;
                
            }
            @include media-breakpoint-up(lg) {
                // right: 0;
                // left: auto;
                left: -21.5em
                // width: 10px !important;
            }
        }
    }
}