@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "~rfs/scss";

.Home {
    
   
    
    .quote {
        padding-top: 0;
        padding-bottom: 0;
        .row {
            margin-bottom: 0;
        }
        &__icon {
            width: 64px;
            width: 64px;
            margin: 0 auto;
            display: block;
        }
        &__content {
            padding-top: 3rem;
            padding-bottom: 3rem;
        }
        &__link {
            margin-top: 1rem;
            text-align: center;
            display: block;
        }
        &__images {
            img {
                object-fit: cover;
                width: 50%;
                
                
                @include media-breakpoint-up(lg) {
                    height: 400px;
                    width: 100%;
                }
                @include media-breakpoint-up(xl) {
                    height: 450px;
                }
            }
        }
    }
    
  
    .partners {
        .swiper-container-horizontal{
            overflow: hidden;
        }
        .swiper-slide {
            img {
                // width: 100%;
            }
        }
        .swiper__nav {
            display: flex;
            margin-top: var(--spacer-6);
            .swiper-button-prev,
            .swiper-button-next {
                // display: block;
                position: relative;
            }
            
            .swiper-button-next {
                right: 0;
                left: auto;
            }
        }
    }
    
    .divisions {
        h2 {
            text-align: center;
        }
        .division {
            
            &__logo {
                max-width: 175px;
                margin: 0 auto;
                display: block;
                padding-top: var(--spacer-5);
                padding-bottom: var(--spacer-5);
            }
        }
    }
    
    .shop-hardware {
        position: relative;
        overflow: hidden;
    
        &__background {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            top: 0;
            object-fit: cover;
            
        }
        &__content {
            // position: relative;
            // z-index: 1;
            // background: #fff;
            // padding: var( --spacer-3);
            // border-radius: 3px;
            
        }
    }
}