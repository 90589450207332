@import "~rfs/scss";
@import '../mixins/headings.scss';

@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@300;600&display=swap');
// @import "../../../../node_modules/rfs/scss";
html, .root {
    font-size: 18px;
    line-height: 27px;
    body {
        font-size: 1em;
        line-height: 1.5rem;
        
        // Headings
        // ================
        
        h1,h2,h3,h4,h5,h6 {
            font-weight: 600 ;
            font-family: "Montserrat", sans-serif;
            // margin-bottom: 1.5em;
        }
        
        // Heading mixins are in src/css/mixins/headings.scss
        h1, .h1 {
            @include h1;
        }
        h2, .h2 {
            @include h2
        }
        h3, .h3 {
              @include h3
        }
        h4, .h4 {
          @include h4
        }
        h5, .h5 {
          @include h5
        }
        
        // Other
        // ===========
        
        a {
            color: var(--link-color);
            text-decoration: none;
            &:hover,
            &:focus {
                color: var(--link-color-focus);
                text-decoration: none;
            }
        }
        
        .blue-text {
            color: var(--brand-blue);
        }
        
        .rarr {
            &::after {
                content: ' →';
            }
        }
        
        .headline {
            // font-size: 4.805em;
            // font-weight: 600;
        }
        
        .bold {font-weight: 600};
        .thin {font-weight: 100;}
        
        .caps {
            text-transform: uppercase;
            font-weight: 600;
        }
    }
}