.quote {
    position: relative;
    overflow: hidden;
    &__icon {
        padding-bottom: 1em;
    }
    &__img {
        img {
            max-width: 150px;
        }
    }
    &__author {
        font-weight: 700;
        text-transform: uppercase;
    }
    &__author-info {
        font-style: italic;
    }
    &__body {
        margin-bottom: var(--spacer-3);
    }
    &__btn {
        margin-top: var(--spacer-5);
    }
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}