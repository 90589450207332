@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "~rfs/scss";

.footer {
    background: var(--brand-black);
    padding-top: var(--spacer-10);
    padding-bottom: var(--spacer-10);
    &__logo {
        max-width: 200px;
        margin: 0 auto;
        display: block;
    }
    
    &__cta {
        margin-bottom: var(--spacer-10) !important;
        
        .footer__cta-btn {
            margin: 0 auto;
            display: block !important;
        }
        
    }
    
    &__navigation {
        margin-top: var(--spacer-5);
        color: #fff ;
        display: block;
        text-align: center;
        li {
            list-style: none;    
        }
        a {
            color: #fff !important;
        }
    }
    
    &__menu {
        margin-left: 0;
        padding-left: 0;
    }
    &__menu-heading {
        margin-bottom: var(--spacer-2);
        display: block;
    }
    
    &__contact {
        ul {
            padding-left: 0;
        }
        li {
            list-style: none;
        }
        a {
            color: #fff;
        }
    }
    
    &__address,
    &__legal{
        color:#616161;
        ul {
            padding-left: 0;
            li{
                list-style: none;
                text-align: center;
                
                
                
                @include media-breakpoint-up(md) {
                    text-align: left;
                display: inline;
                }
            }
        }
        a {
            color:#616161;
        }
    }
    
    &__company,
    &__street {
       &:after {
           content: ' | ';
       }
    }
    &__legal {
        text-align: right;
    }
}