.contact-form {
    
}
#validation {
    .success {
        margin-bottom: var(--spacer-3);
        border: 3px solid var(--brand-green);
        padding: var(--spacer-3);
        text-align: center;
        color: var(--brand-green);
    }
}

.form-group {
    input[type="email"], 
    input[type="tel"], 
    input[type="text"], 
    textarea {
        border: 1px solid #807d82;
        border-radius: 3px;
        padding: var(--spacer-2) ;
        background: #e6e5e6;
        &:focus,
        &:active{
            border: 1px solid var(--brand-blue);   
            box-shadow: none;
        }
    }
    
    textarea {
        height: 10em;
    }
    
    .react-tel-input {
        .form-control {
            height: auto;
            width: 100%;
            padding-left: 50px;
        }
    }
}

    
input[type="submit"]{
    margin-top: var(--spacer-2) !important;    
}


#validation {
    .success {
        border: 5px solid #99c288;
        color: #99c288;
    }
}