@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "~rfs/scss";

.OperationsLogistics {
    .quote {
            padding-top: 0;
            padding-bottom: 0;
            .row {
                margin-bottom: 0;
            }
            &__icon {
                width: 64px;
                width: 64px;
                margin: 0 auto;
                display: block;
            }
            &__content {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
            &__link {
                margin-top: 1rem;
                text-align: center;
                display: block;
            }
            &__images {
                img {
                    object-fit: cover;
                    width: 50%;
                    
                    
                    @include media-breakpoint-up(lg) {
                        height: 400px;
                        width: 100%;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 450px;
                    }
                }
            }
        }
        
}