.post {
    &__intro {
        margin-bottom: var(--spacer-5);
        text-align: center;
    }
    &__image {
        margin-top: var(--spacer-2);    
        img {
            width: 100%;
        }
    }
}
