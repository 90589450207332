@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "~rfs/scss";
.logo-slider {
    .swiper-wrapper {
        align-items: center;
        
    }
    &.swiper-container-horizontal{
        overflow:hidden;
    }
    .swiper-slide {
        img {
            width: 50%;
            margin: 0 auto;
            display: block;
            vertical-align: middle;
            filter: grayscale(1);
            opacity: .7;
                
            @include media-breakpoint-up(sm) {
                width: 90%;
            }
            @include media-breakpoint-up(md) {
                width: 80%;
            }
            @include media-breakpoint-up(lg) {
                width: 60%;
            }
        }
    }
    &--color {
        .swiper-slide {
            img {
                filter: none;
                opacity: 1;
            }
        }
    }
}