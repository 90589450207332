.job-list {
    a{
        &:hover {
            .job-list__job {
                background: var(--soft-grey);
            }
        }
    }
    &__job {
        border: 1px solid var(--light-grey);
        padding-top: 1em;
        padding-bottom: 1em;
        margin-bottom: 0 !important;
    }
    &__job-title {
        margin-top: 0;
        border-right: 1px solid var(--light-grey);
    }
}