@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
@import "~rfs/scss";

.WorkforceOptimization {
    .ForWork__logo {
        max-width: 200px;
    }
    .stats {
        .stat {
            margin-bottom: 2rem;
            &__number {
                
            }
            &__context {
                
            }
            &:last-of-type{
                margin-bottom: auto;
            }
        }
    }
    
    .quote {
            padding-top: 0;
            padding-bottom: 0;
            .row {
                margin-bottom: 0;
            }
            &__icon {
                width: 64px;
                width: 64px;
                margin: 0 auto;
                display: block;
            }
            &__content {
                padding-top: 3rem;
                padding-bottom: 3rem;
            }
            &__link {
                margin-top: 1rem;
                text-align: center;
                display: block;
            }
            &__images {
                img {
                    object-fit: cover;
                    width: 50%;
                    
                    
                    @include media-breakpoint-up(lg) {
                        height: 400px;
                        width: 100%;
                    }
                    @include media-breakpoint-up(xl) {
                        height: 450px;
                    }
                }
            }
        }
    
    
    // 
    // OLD
    // 
    .swiper-slide {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    .swiper-wrapper {
        z-index: 0;
    }
    .features{
        &__content {
            z-index: 1;
            position: relative;
            margin-top: var(--spacer-5);
            margin-bottom: var(--spacer-5);
        }
    
    }
    .stats-section {
        background: #f1f1f1 ;
    }
    
    .stats {
        border-bottom: 1px solid #1d1d1d;
        padding-top: var(--spacer-2);
        padding-bottom: var(--spacer-2);
        &__number {
            color: var(--brand-blue);
            font-size: 2.5em;
        }
        &:last-child {
            border: none;
        }
    }
}