$cctvSectionHeight: 75vh;
$barcodeSectionHeight: 75vh;


.computing {
    &__content {
    
        .cctv {
            // // height: $barcodeSectionHeight;
            // position: relative;
            // background: #fff;
            // &__content {
            //     z-index: 2;
            //     position: relative;
            //     // height: $cctvSectionHeight;
            //     .row {
            //         height: 100%;
            //     }
            // }
            &__video{
            
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
                object-position: 80% 0%;
                
            }
            // &__video-overlay {
            //     z-index: 1;
            //     background-color:#192c36;
            //     opacity: .3;
                
            // }
        }
        .barcode {
            height: $barcodeSectionHeight;
            position: relative;
            &__content {
                height: $barcodeSectionHeight;
                z-index: 2;
                position: relative;
                .row {
                    height: 100%;
                }
            }
            &__video,
            &__video-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 0;
            }
            &__video-overlay {
                z-index: 1;
                background-color:#192c36;
                opacity: .3;
                
            }
        }    
    }
}
