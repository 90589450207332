:root {
    /* Brand Colors */
    --brand-blue: #265b97;
    --brand-blue-light: #4a85c7;
    --brand-black: #363537;
    --light-grey: #f1f1f1;
    --soft-grey: #e9f4f5;
    /*Body BG*/
    --body-bg: var(--white);
    
    // Typography
    --link-color: var(--brand-blue);
    --link-focus: var(--brand-blue-light);
    
}

.soft-grey-bg {
    background: var(--soft-grey);
}

.light-grey-bg {
    background: var(--light-grey);
}