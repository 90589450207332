@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";
.News {
    
    .page-hero {
        padding-bottom: 0;
    }
    .latest {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &__posts {
            padding-top: 0;
            .post-list {
                overflow: hidden !important;
            }
        }
    }
    
    
    .card {
        margin-bottom: var(--spacer-5);
        &__actions {
            @include media-breakpoint-up(md) {
                position: absolute;
                bottom: var(--spacer-2);
                right: var(--spacer-2);
            }
        }
        img {
            object-fit: cover;
            @include media-breakpoint-up(md) {
                height: 150px;
            }
            @include media-breakpoint-up(xl) {
                height: 250px;
            }
        }
        
        
        @include media-breakpoint-up(md) {
            min-height: 400px;
        }
        @include media-breakpoint-up(lg) {
            min-height: 500px;
        }
    }
}